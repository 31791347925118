import React, { useState, FunctionComponent, useEffect } from 'react';
import { Button, Space, Row, Col } from 'antd'
import { ApiBroker, ApiTrader, ApiPosition, ApiAccount, ApiWin } from '../models';
import { instance } from '../utils';
import { Link } from 'react-router';
import { Trader } from '../components/trader';
import { Broker } from '../components/broker';
import { Position } from '../components/position';
import { PlusOutlined } from '@ant-design/icons'

const TradersPage:FunctionComponent<{}> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)

  const [traders, setTraders] = useState<ApiTrader[]>([])

  const fetchTraders = async () => {
    setLoading(true)
    try {
      const traders = await instance.get('/v1/traders')
      setTraders(traders.data.traders)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTraders()
  }, [])

  return <>

    <div style={{ float: 'right'}}>
      <Button onClick={() => fetchTraders()} loading={loading}>Refresh</Button>
    </div>

    <h2>Traders</h2>
    <Row gutter={[16, 16]}>
      {
        traders.length === 0 ? <p>No trader</p> : null
      }
      {
        traders?.map(trader => <Col><Trader trader={trader} {...props} /></Col>)
      }
    </Row>
    <Link to="/traders/create"><Button type="primary" icon={<PlusOutlined />}>Create trader</Button></Link>
    <br /><br />
  </>
}

export { TradersPage }
