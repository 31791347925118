import React, { useState, FunctionComponent, useEffect } from 'react';
import { Space, Row, Col, Button, Timeline, Tooltip, Card, Alert, Flex, Typography } from 'antd'
import { ApiBroker, ApiAccount, ApiWin } from '../models';
import { instance } from '../utils';
import { Link } from 'react-router';
import { Broker } from '../components/broker';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';

interface WinProps {
  win: ApiWin | null
  account: number
}
interface TimelinePoint {
  action: string
  date: string
  now: 'opened' | 'closed'
  price: number
  currency: string
  profit: number
  quantity: number
  type: 'open' | 'close'
}
const Win:FunctionComponent<WinProps> = (props) => {
  if (!props.win) {
    return <p>Loading...</p>
  }

  const account = props.account - props.win.win

  return props.win ? <div style={{ fontFeatureSettings: "'ss01' 1, 'tnum' 1" }}>
  <span><b>Won</b> {props.win.win.toFixed(2)} → {(props.win.win * 0.7).toFixed(2)}</span><br />
  <span><b>Percent</b> {(props.win.win / (account - props.win.win) * 100).toFixed(2)} → {(props.win.win / (account - props.win.win) * 70).toFixed(2)}</span><br />
  <span><b>Positions</b> {props.win.positionsCount}</span>
</div> : <p>Loading...</p>
}

const Statuses: FunctionComponent<{}> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)

  const [accountsValue, setAccountsValue] = useState<number>(0)
  const [todayWin, setTodayWin] = useState<ApiWin | null>(null)
  const [monthWin, setMonthWin] = useState<ApiWin | null>(null)
  const [monthRollingWin, setMonthRollingWin] = useState<ApiWin | null>(null)
  const [yearWin, setYearWin] = useState<ApiWin | null>(null)
  const [yearFiscalWin, setYearFiscalWin] = useState<ApiWin | null>(null)
  const [win, setWin] = useState<ApiWin | null>(null)
  const [timeline, setTimeline] = useState<Array<TimelinePoint> | null>(null)

  const fetchAccounts = async () => {
    const accounts = await instance.get('/v1/accounts')

    let value = 0
    for (const account of accounts.data.accounts as ApiAccount[]) {
      value += account.total
    }
    setAccountsValue(value)
  }
  const fetchTodayWin = async () => {
    let d = new Date()
    d.setHours(0, 0, 0, 0)

    const win = await instance.get(`/v1/positions/win?since=${d.toISOString()}`)
    setTodayWin(win.data)
  }
  const fetchMonthWin = async () => {
    let d = new Date()
    d.setHours(0, 0, 0, 0)
    d.setDate(1)

    const win = await instance.get(`/v1/positions/win?since=${d.toISOString()}`)
    setMonthWin(win.data)
  }
  const fetchMonthRollingWin = async () => {
    let d = new Date()
    d.setHours(0, 0, 0, 0)
    d.setMonth(d.getMonth() - 1)

    const win = await instance.get(`/v1/positions/win?since=${d.toISOString()}`)
    setMonthRollingWin(win.data)
  }
  const fetchYearWin = async () => {
    let d = new Date()
    d.setHours(0, 0, 0, 0)
    d.setFullYear(d.getFullYear() - 1)

    const win = await instance.get(`/v1/positions/win?since=${d.toISOString()}`)
    setYearWin(win.data)
  }
  const fetchYearFiscalWin = async () => {
    let d = new Date()
    d.setHours(0, 0, 0, 0)
    d.setMonth(0)
    d.setDate(1)

    const win = await instance.get(`/v1/positions/win?since=${d.toISOString()}`)
    setYearFiscalWin(win.data)
  }
  const fetchWin = async () => {
    const win = await instance.get('/v1/positions/win')
    setWin(win.data)
  }
  const fetchTimeline = async () => {
    const timeline = await instance.get('/v1/positions/timeline?take=100')
    setTimeline(timeline.data.timeline)
  }

  const fetch = async () => {
    setLoading(true)
    await Promise.all([
      fetchAccounts(),
      fetchTodayWin(),
      fetchMonthWin(),
      fetchMonthRollingWin(),
      fetchYearWin(),
      fetchYearFiscalWin(),
      fetchWin(),
      fetchTimeline()
    ])
    setLoading(false)
  }


  useEffect(() => {
    fetch()
  }, [])

  return <>
    <Flex justify='space-between'>
      <h2>Winning</h2>
      <Button onClick={() => fetch()} loading={loading}>Refresh</Button>
    </Flex>
    <Alert type='info' message='Info' description={<>
      Won is a sum of any currency<br />
      Percent is calculated with current total account value<br />
      A position is a bundle of an opening and a closing<br />
      A → convert the value with the 30% flat tax
    </>} showIcon />
    <br />

    <Space>
      <Card title='Today'>
        <Win win={todayWin} account={accountsValue} />
      </Card>
      <Card title='This month'>
        <Win win={monthWin} account={accountsValue} />
        <br />
        <Typography.Title level={5}>Rolling</Typography.Title>
        <Win win={monthRollingWin} account={accountsValue} />
      </Card>

      <Card title='This year'>
        <Win win={yearFiscalWin} account={accountsValue} />
        <br />
        <Typography.Title level={5}>Rolling</Typography.Title>
        <Win win={yearWin} account={accountsValue} />
      </Card>

      <Card title='Since the beginning'>
        <Win win={win} account={accountsValue} />
      </Card>
    </Space>
    <br /><br />

    <h2>Timeline</h2>
    
    {
      timeline === null ? 'Loading...' :
      <Timeline mode="left">
        {
          timeline.map((point) => {
            return <Timeline.Item label={<Tooltip title={moment(point.date).format('YYYY-MM-DD LTS')}><span><u className="dotted">{moment(point.date).fromNow()}</u></span></Tooltip>}>
              <span style={{ fontFeatureSettings: "'ss01' 1, 'tnum' 1" }}>
                {point.type === 'open' ? 'Opened' : 'Closed'} {point.action} × {point.quantity} @{point.price}{point.currency}<br />
                {point.type === 'open' ? <i>{point.now === 'opened' ? 'still open' : 'now closed'}</i> : <i>profit of {point.profit}{point.currency} ({(point.profit / ((point.quantity * point.price) - point.profit) * 100).toFixed(2)}%)</i> }
              </span>
            </Timeline.Item>
          })
        }
      </Timeline>
    }
    
  </>
}

export { Statuses }