import React, { FunctionComponent, useState, useEffect } from 'react';
import "./App.css"
import { Route, Link, Routes, useLocation } from 'react-router'
import { Statuses } from './pages/statuses';
import { CreateTrader } from './pages/createTrader';
import { ConfigProvider, Layout, Menu, theme } from 'antd';
import { TraderPage } from './pages/trader';
import { EditTrader } from './pages/editTrader';
import { TradersPage } from './pages/traders';
import { QrcodePage } from './pages/qrcode';
import { AddPositionTrader } from './pages/addPositionTrader';
import { PositionsPage } from './pages/positions';
import { BrokersPage } from './pages/brokers';
import { PositionPage } from './pages/position';
import { BrokerPage } from './pages/broker';
import { BrokerAssetPage } from './pages/brokerAsset';

const App:FunctionComponent<{}> = (props) => {

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  
  const splitPath = (path: string) => {
    const splitPath = path.split('/')
    let endPaths = [splitPath[0]]
    if (splitPath.length >= 2) {
      for (let i = 1; i <= splitPath.length - 1; i++) {
        endPaths.push(endPaths[i - 1] + '/' + splitPath[i])
      }
    }

    return endPaths
  }

  const location = useLocation()
  const [splitedPath, setSplitedPath] = useState(splitPath(location.pathname))

  useEffect(() => {
    setSplitedPath(splitPath(location.pathname))
  }, [location.pathname])

  return <ConfigProvider
    theme={{
      token: {
        fontFamily: "'Inter', sans-serif"
      }
    }}
  ><Layout className="layout" >
      <Layout.Header>
        <span style={{
          display: 'flex',
          float: 'left',
          color: 'white',
          marginRight: '2em',
          paddingRight: '1em',
          fontWeight: 'bold',
          alignItems: 'center',
        }}>
          <img src='/favicon.svg' width='30px' height='30px' style={{ marginRight: '1em', borderRadius: '2px' }} />
          STONKS
        </span>
        <Menu theme="dark" mode="horizontal" selectedKeys={splitedPath}>
          <Menu.Item key="/"><Link to="/">Home</Link></Menu.Item>
          <Menu.Item key="/brokers"><Link to="/brokers">Brokers</Link></Menu.Item>
          <Menu.Item key="/traders"><Link to="/traders">Traders</Link></Menu.Item>
          <Menu.Item key="/positions"><Link to="/positions">Positions</Link></Menu.Item>
        </Menu>
        
      </Layout.Header>
      <Layout.Content style={{ padding: '0 50px', height: '100%' }}>
        <div style={{
          background: colorBgContainer,
          minHeight: 280,
          padding: 24,
          borderRadius: borderRadiusLG,
        }}>
          <Routes>
            <Route path="/" element={<Statuses />} />
            <Route path="/qrcode" element={<QrcodePage />} />
            <Route path="brokers" element={<BrokersPage />} />
            <Route path="/brokers/:id" element={<BrokerPage />} />
            <Route path='/brokers/:broker/assets/:asset' element={<BrokerAssetPage />} />
            <Route path="/positions" element={<PositionsPage />} />
            <Route path="/positions/:id" element={<PositionPage />} />
            <Route path="/traders" element={<TradersPage />} />
            <Route path="/traders/create" element={<CreateTrader />} />
            <Route path="/traders/trader/:id" element={<TraderPage />} />
            <Route path="/traders/trader/:id/edit" element={<EditTrader />} />
            <Route path="/traders/trader/:id/addPosition" element={<AddPositionTrader />} />
          </Routes>
        </div>
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center' }}></Layout.Footer>
    </Layout>
  </ConfigProvider>
}

export { App }