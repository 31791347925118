import React, { FunctionComponent, useEffect, useState } from 'react'
import { ApiAsset, ApiBroker, ApiOption, ApiPosition } from '../models'
import { instance } from "../utils"
import { List, Space } from 'antd'
import { useParams } from 'react-router'

type BrokerAssetParams = { broker: string, asset: string }

interface BrokerAssetPageProps {}

export const BrokerAssetPage: FunctionComponent<BrokerAssetPageProps> = ({ }) => {
  const params = useParams<BrokerAssetParams>()
  const [broker, setBroker] = useState<ApiBroker | null>(null)
  const [options, setOptions] = useState<ApiOption[]>([])

  const fetchBroker = async () => {
    const broker = await instance.get(`/v1/brokers/broker/${params.broker}`)
    setBroker(broker.data.broker)
  }

  const fetchOptions = async () => {
    const options = await instance.get(`/v1/brokers/broker/${params.broker}/assets/asset/${params.asset}/options`)
    setOptions(options.data.options)
  }

  useEffect(() => {
    fetchBroker()
    fetchOptions()
  }, [params.broker, params.asset])

  return <>
    <h2>Broker {params.broker} asset {params.asset}</h2>
    <div>
      <h3>Options</h3>
      <List>
        {
          options.map(option => <List.Item>
            <List.Item.Meta title="Symbol" description={option.symbol} />
            <List.Item.Meta title="Size" description={option.size} />
            <List.Item.Meta title="Type" description={option.meta?.type} />
            <List.Item.Meta title="Strike" description={option.meta?.strikePrice} />
            <List.Item.Meta title="Expire" description={option.meta?.expiration.split('T')[0]} />
          </List.Item>)
        }
      </List>
    </div>
  </>
}