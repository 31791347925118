import { ApiPosition } from "../models"
import { FunctionComponent, useEffect, useState } from "react"
import React from "react"
import { Col, Row, Space, Spin } from "antd"
import { Position } from "../components/position"
import { instance } from "../utils"

interface PositionsPageProps {
}

const PositionsPage: FunctionComponent<PositionsPageProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true)

  const [positions, setPositions] = useState<ApiPosition[]>([])
  const fetchPositions = async () => {
    setLoading(true)
    try {
      const positions = await instance.get('/v1/positions/current')
      setPositions(positions.data.positions)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPositions()
  }, [])

  if (loading) {
    return <>
      <h2>Positions</h2>
      <Space>
        <Spin />
      </Space>
    </>
  }
  
  return <>
    <h2>Positions</h2>
    <Row gutter={[16, 16]}>
      {
        positions.length === 0 ? <p>No positions</p> : null
      }
      {
        positions?.map(position => <Col><Position position={position} {...props} /></Col>)
      }
    </Row>
  </>
}

export { PositionsPage }