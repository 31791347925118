import React, { FunctionComponent, useState, useEffect } from "react"
import { ApiTraderType, ApiBroker, ApiTrader } from "../models"
import { instance } from "../utils"
import { Form, Button, Select, Input, InputNumber, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useParams } from "react-router";

type TraderParams = { id: string }

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

const AddPositionTrader:FunctionComponent<{}> = (props) => {
  const params = useParams<TraderParams>()
  const [form] = Form.useForm();
  const [trader, setTrader] = useState<ApiTrader | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      const trader = await instance.get(`/v1/traders/trader/${params.id}`)
      trader.data.trader.meta = JSON.stringify(trader.data.trader.meta, null, 2)
      setTrader(trader.data.trader)
    }
    fetchData()
  }, [params.id])

  const onFinish = async (values: any) => {
    try {
      const res = await instance.put(`/v1/traders/trader/${params.id}/position`, {
        action: values.action,
        quantity: parseFloat(values.quantity),
        price: parseFloat(values.price),
        currency: values.currency,
      })
      if (res.data.success) {
        notification.success({
          message: 'Position added',
          description: 'The trader will use this position saved'
        })
      }
    } catch (e) {
      console.error(e)
      notification.error({
        message: 'Error',
        description: e.toString()
      })
    }
  }

  if (!trader) {
    return <p>Loading...</p>
  }

  return <>
    <h2>Add position to a trader</h2>
    <p>Add manually a position for a trader that were added but not saved in database (e.g. because of a synchronization problem)</p><br />
    <Form {...layout} form={form} initialValues={trader} onFinish={onFinish}>
      <Form.Item name="action" label="Action" extra="Action">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="quantity" label="Quantity" extra="Block of buy sell (bigger number for cheap actions, fraction for expensive)">
        <InputNumber placeholder="1"  />
      </Form.Item>
      <Form.Item name="price" label="Price" extra="Price bought including fees">
        <InputNumber placeholder="8"  />
      </Form.Item>
      <Form.Item name="currency" label="Currency" extra="Currency used to place this position">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  </>
}

export { AddPositionTrader }