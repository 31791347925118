import React, { FunctionComponent, useEffect, useState } from "react";
import { Card, Typography, Button, Modal, notification, Spin, Alert } from "antd";
import { ApiBroker, ApiAccount } from "../models";
import { instance } from "../utils";
import { Link } from "react-router";

const { Text } = Typography

interface BrokerProps {
  broker: ApiBroker
}

enum AccountsState {
  Loaded,
  Loading,
  Failed,
}

const Broker:FunctionComponent<BrokerProps> = (props) => {
  const [accountsState, setAccountsState] = useState<AccountsState>(AccountsState.Loading)
  const [accounts, setAccounts] = useState<ApiAccount[]>([])

  const fetchAccounts = async () => {
    setAccountsState(AccountsState.Loading)
    try {
      const accounts = await instance.get(`/v1/brokers/broker/${props.broker.name}/accounts`)
      setAccounts(accounts.data.accounts)
      setAccountsState(AccountsState.Loaded)
    } catch (e) {
      setAccountsState(AccountsState.Failed)
    }
  }

  useEffect(() => {
    fetchAccounts()
  }, [props.broker.name])

  return <Link to={`/brokers/${props.broker.name}`}><Card hoverable style={{
      width: 300,
      borderColor: props.broker.state === 'paused' ? '#e74c3cf0' : undefined,
      backgroundColor: props.broker.state === 'paused' ? '#e74c3c60' : undefined,
      animation: props.broker.state === 'paused' ? 'pulse 2s infinite': undefined
    }}>
      <span><b>{props.broker.name}</b></span><br />
      { accountsState === AccountsState.Loading ? <Spin /> : null}
      { accountsState === AccountsState.Failed ? <Alert message="Cannot get accounts" /> : null}
      <span>
        <ul>
        {
          accounts?.map(account => {
            return <li style={{ fontFeatureSettings: "'ss01' 1, 'tnum' 1" }}>{account.total.toFixed(4)}/{account.free.toFixed(4)} {account.currency}</li>
          })
        }
        </ul>
      </span>
      <span><i>{props.broker.state}</i></span>
    </Card></Link>
}

export { Broker }