import React, { FunctionComponent, useEffect, useState } from "react"
import { ApiPrice, ApiTrader } from "../../models"
import { instance } from "../../utils"

interface TraderProps {
  trader: ApiTrader
  full?: boolean
}

const TraderMeta:FunctionComponent<TraderProps> = (props) => {
  const [price, setPrice] = useState<ApiPrice | null>(null)

  const fetchPrice = async () => {
    if (!props.trader) {
      return
    }
    const prices = await instance.get(`/v1/prices/${props.trader.broker}/${props.trader.action}/${props.trader.currency}`)
    setPrice(prices.data.price)
  }

  useEffect(() => {
    fetchPrice()
  }, [props.trader])

  switch (props.trader.type) {
    case 'DumbTrader':
      return <div style={{ fontFeatureSettings: "'ss01' 1, 'tnum' 1" }}>
        { props.full ? <>
          <h3>{props.trader.quantity} × {props.trader.action} @{props.trader.minWin} - {props.trader.maxPositions} max</h3>
          <b>{props.trader.type}</b> on {props.trader.broker}<br />
          </> : null
        }
        <span><b>Interval</b> {props.trader.meta.secondsInterval} seconds</span><br />
        <span><b>Direction</b> {props.trader.meta.lastDirection}</span><br />
      </div>
    case 'MaCrossPrice':
      return <div style={{ fontFeatureSettings: "'ss01' 1, 'tnum' 1" }}>
        { props.full ? <>
          <h3>{props.trader.quantity} × {props.trader.action} @{props.trader.minWin} - {props.trader.maxPositions} max</h3>
          <b>{props.trader.type}</b> on {props.trader.broker}<br />
          </> : null
        }
        <span><b>Interval</b> {props.trader.meta.secondsInterval} seconds</span><br />
        <span><b>Direction</b> {props.trader.meta.lastDirection}</span><br />
        <span><b>Ma width</b> {props.trader.meta.maWidth}</span><br />
        <span><b>Ma type</b> {props.trader.meta.maType}</span><br />
        <span><b>Max full value</b> {price && props.trader ? (price.ask * props.trader.quantity * props.trader.maxPositions).toFixed(2) + ' ' + props.trader.currency : 'Loading...'}</span><br />
      </div>
    case 'TickDumbTrader':
      return <div style={{ fontFeatureSettings: "'ss01' 1, 'tnum' 1" }}>
        { props.full ? <>
          <h3>{props.trader.quantity} × {props.trader.action} @{props.trader.minWin} - {props.trader.maxPositions} max</h3>
          <b>{props.trader.type}</b> on {props.trader.broker}<br />
          </> : null
        }
        <span><b>Direction</b> {props.trader.meta.direction}</span><br />
        <span><b>Last SMA</b> {props.trader.meta.lastSma}</span><br />
        <span><b>Far below SMA</b> {props.trader.meta.farBelowSma.toString()}</span><br />
      </div>
    case 'OptionInterestTrader':
      return <div style={{ fontFeatureSettings: "'ss01' 1, 'tnum' 1" }}>
        { props.full ? <>
          <h3>{props.trader.quantity} × {props.trader.action} @{props.trader.minWin} - {props.trader.maxPositions} max</h3>
          <b>{props.trader.type}</b> on {props.trader.broker}<br />
          </> : null
        }
        <span><b>Option multiplier</b> {props.trader.meta.optionMultiplier}</span><br />
      </div>

  }
  return <span><i>Unknown trader type {props.trader.type}</i></span>
}

export { TraderMeta }