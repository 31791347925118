import React, { FunctionComponent, useEffect, useState } from 'react'
import { ApiAsset, ApiBroker, ApiPosition } from '../models'
import { instance } from "../utils"
import { Button, List, Modal, notification, Space } from 'antd'
import { Link, useParams } from 'react-router'
import { ModalFuncProps } from "antd/lib/modal/interface";

type BrokerParams = { id: string }

interface BrokerPageProps {}

export const BrokerPage: FunctionComponent<BrokerPageProps> = ({ }) => {
  const params = useParams<BrokerParams>()
  const [modal, contextHolder] = Modal.useModal()

  const [broker, setBroker] = useState<ApiBroker | null>(null)
  const [assets, setAssets] = useState<ApiAsset[]>([])
  const [positions, setPositions] = useState<ApiPosition[]>([])
  // TODO: add accounts

  const fetchBroker = async () => {
    const broker = await instance.get(`/v1/brokers/broker/${params.id}`)
    setBroker(broker.data.broker)
  }
  const fetchAssets = async () => {
    const assets = await instance.get(`/v1/brokers/broker/${params.id}/assets`)
    setAssets(assets.data.assets)
  }
  const fetchPositions = async () => {
    const positions = await instance.get(`/v1/brokers/broker/${params.id}/positions`)
    setPositions(positions.data.positions)
  }

  useEffect(() => {
    fetchBroker()
    fetchAssets()
    fetchPositions()
  }, [params.id])

  const modalSwitchState: ModalFuncProps = {
    title: 'Are you sure?',
    content: 'No broker will be able to order or subscribe, you must be sure to be outside market opening hours.',
    onOk: async () => {
      try {
        await instance.put(`/v1/brokers/broker/${broker!.name}`, {
          broker: {
            state: broker!.state === 'started' ? 'paused' : 'started'
          }
        })
        notification.success({
          message: 'Broker modified',
          description: 'State has been modified'
        })
      } catch (e) {
        console.error(e)
        notification.error({
          message: 'Error',
          description: e.toString()
        })
      }
    }
  }

  return <>
    <h2>Broker {params.id}</h2>
    <div>
      <h3>Data</h3>
      <pre>{JSON.stringify(broker, null, 2)}</pre>
      <Button onClick={() => modal.confirm(modalSwitchState)}>Switch state</Button>
    </div>
    <div>
      <h3>Positions</h3>
      <List>
        {
          positions.map(position => <List.Item>
            <List.Item.Meta title="Action" description={position.action} />
            <List.Item.Meta title="Quantity" description={position.quantity} />
          </List.Item>)
        }
      </List>
    </div>
    <div>
      <h3>Assets</h3>
      <List>
        {
          assets.map(asset => <List.Item>
            <List.Item.Meta title="Symbol" description={asset.symbol} />
            { asset.meta && Object.keys(asset.meta).map(key => <List.Item.Meta title={key} description={asset.meta![key]} />)}
            <Button href={`/brokers/${params.id}/assets/${asset.symbol}`}>See more</Button>
          </List.Item>)
        }
      </List>
    </div>
    {contextHolder}
  </>
}