import React, { FunctionComponent, useState, useEffect } from "react"
import { ApiTraderType, ApiBroker } from "../models"
import { instance } from "../utils"
import { Form, Button, Select, Input, InputNumber, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { LinkOutlined } from "@ant-design/icons";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

const CreateTrader:FunctionComponent = () => {
  const [form] = Form.useForm();
  const [traderTypes, setTraderTypes] = useState<ApiTraderType[]>([])
  const [brokers, setBrokers] = useState<ApiBroker[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const traderTypes = await instance.get('/v1/traders/types')
      setTraderTypes(traderTypes.data.types)

      const brokers = await instance.get('/v1/brokers')
      setBrokers(brokers.data.brokers)
    }
    fetchData()
  }, [])

  const onFinish = async (values: any) => {
    values.meta = JSON.parse(values.meta)

    try {
      const res = await instance.post('/v1/traders/trader', {
        trader: {
          broker: values.broker,
          type: values.type,
          action: values.action,
          quantity: parseFloat(values.quantity),
          maxPositions: parseInt(values.maxPositions),
          minWin: parseFloat(values.minWin),
          currency: values.currency,
          offsetSeconds: parseInt(values.offsetSeconds),
          meta: values.meta
        }
      })
      if (res.data.success) {
        notification.success({
          message: 'Trader created',
          description: 'The trader will trade shortly'
        })
      }
    } catch (e) {
      console.error(e)
      notification.error({
        message: 'Error',
        description: e.toString()
      })
    }
  }

  return <>
    <h2>Create trader</h2>
    <p>
      Add a new trader to the system. It will be launched and restarded with the same state (settings and positions) if the application crash.<br />
      You shouldn't have 2 traders on the same broker with the same action. It causes conflicts and order rejections.
    </p>
    <p>
      NB: for <b>Bourse de Paris</b> stocks, there is the  <a href="https://fr.wikipedia.org/wiki/Taxe_sur_les_transactions_financi%C3%A8res_(France)" rel="noopener noreferrer" target="_blank">TTF (taxes sur les transactions financieres) <LinkOutlined /></a> that are included in the computation of prices when buying
      (e.g. buying <b>@30EUR</b> will be <b>@30.10EUR</b>, note the round, and it uses this price for computations).
    </p>
    <br /><br />
    <Form {...layout} form={form} onFinish={onFinish}>
      <Form.Item name="broker" label="Broker" extra="Broker used to trade the action">
        <Select>
          {
            brokers.map(broker => {
              return <Select.Option key={broker.name} value={broker.name}>{broker.name}</Select.Option>
            })
          }
        </Select>
      </Form.Item>
      <Form.Item name="type" label="Trader type" extra="Type of trader used">
        <Select>
          {
            traderTypes.map(type => {
              return <Select.Option key={type.name} value={type.name}>{type.name}</Select.Option>
            })
          }
        </Select>
      </Form.Item>
      <Form.Item name="action" label="Action" extra="e.g. CS.D.BITCOIN.CFE.IP for Ig, IQQWd_EQ for trading212">
        <Input placeholder="IQQWd_EQ" />
      </Form.Item>
      <Form.Item name="quantity" label="Quantity" extra="Block of buy sell (bigger number for cheap actions, fraction for expensive)">
        <InputNumber placeholder="1" />
      </Form.Item>
      <Form.Item name="maxPositions" label="Max positions" extra="Number of positions at the same time (nb actions = quantity . maxPositions)">
        <InputNumber placeholder="8" />
      </Form.Item>
      <Form.Item name="minWin" label="Minimum to win/action" extra="Sell if the win/action is >= minWin">
        <InputNumber placeholder="0.02" />
      </Form.Item>
      <Form.Item name="currency" label="Currency" extra="Currency used by the trader to buy the action">
        <Input placeholder="EUR"  />
      </Form.Item>
      <Form.Item name="offsetSeconds" label="Start offset" extra="This trader will start x seconds after the engine">
        <InputNumber placeholder="60"  />
      </Form.Item>
      <Form.Item name="meta" label="Trader meta" extra="JSON, depends of the trader type, check source">
          <TextArea placeholder="{}" />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  </>
}

export { CreateTrader }