import { Button, InputNumber, notification } from "antd"
import Modal from "antd/lib/modal"
import { ModalFuncProps } from "antd/lib/modal/interface"
import React, { FunctionComponent, useEffect, useState } from "react"
import { ApiPosition } from "../models"
import { instance } from "../utils"
import { useParams } from "react-router"

type PositionParams = { id: string }

const PositionPage:FunctionComponent<{}> = (props) => {
  const params = useParams<PositionParams>()

  const [position, setPosition] = useState<ApiPosition>()
  const [positionPrice, setPositionPrice] = useState<number>(0)
  const [modal, contextHolder] = Modal.useModal();

  const fetchPosition = async () => {
    const position = await instance.get(`/v1/positions/position/${params.id}`)
    setPosition(position.data.position)
  }
  useEffect(() => {
    fetchPosition()
  }, [params.id])

  const modalDelete: ModalFuncProps = {
    title: 'Are you sure?',
    content: 'This will set the position as closed. Is this already sold on broker?',
    onOk: async () => {
      try {
        const res = await instance.post(`/v1/positions/position/${params.id}/closedManually`, {
          price: positionPrice
        })
        notification.success({
          message: 'Position closed',
          description: `Position set as sold`
        })
        // TODO: go back
      } catch (e) {
        console.error(e)
        notification.error({
          message: 'Error',
          description: e.toString()
        })
      }
    }
  }

  return <>
    <pre>{JSON.stringify(position, null, 2)}</pre>
    <InputNumber onChange={(v: number | null) => setPositionPrice(v!)} value={positionPrice} />
    <Button onClick={() => modal.confirm(modalDelete)}>Close manually</Button>

    {contextHolder}
  </>
}

export { PositionPage }