import React, { FunctionComponent } from "react";
import { Card, Typography, Button, Modal, notification } from "antd";
import { ApiTrader } from "../../models";
import { instance, getColor } from "../../utils";
import { TraderMeta } from "./meta";
import { Link } from "react-router";

const { Text } = Typography

interface TraderProps {
  trader: ApiTrader
}

const Trader:FunctionComponent<TraderProps> = (props) => {
  return <Link to={`/traders/trader/${props.trader.id}`}><Card
    hoverable
    style={{
      maxWidth: 400,
      backgroundColor: getColor(props.trader.id) + '20',
      borderColor: getColor(props.trader.id) + '60',
      fontFeatureSettings: "'ss01' 1, 'tnum' 1"
    }}
    >
    <span><b>{props.trader.quantity} × {props.trader.action} @{props.trader.minWin} - {props.trader.maxPositions} max</b></span><br />
    <span>{props.trader.type} on {props.trader.broker}</span><br /><br />
    <span>Last run on <b>{new Date(props.trader.lastRun).toLocaleString()}</b><br /></span>
    <TraderMeta trader={props.trader} />
  </Card></Link>
}

export { Trader }