import React, { FunctionComponent, useState, useEffect } from "react";
import { Card, Typography, Skeleton, Badge, Tag } from "antd";
import { ApiPosition, ApiPrice } from "../models";
import { instance, getColor } from "../utils";
import { Link } from "react-router";

const { Text } = Typography

interface PositionProps {
  position: ApiPosition
}

const Position:FunctionComponent<PositionProps> = (props) => {
  const [price, setPrice] = useState<ApiPrice | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      const prices = await instance.get(`/v1/prices/${props.position.broker}/${props.position.action}/${props.position.currency}`)
      setPrice(prices.data.price)
    }
    fetchData()
  }, [props.position])

  let win = 0
  if (price) {
    if (props.position.quantity > 0) {
      win = ((price.lastSale ? price.lastSale.price : price.bid) - props.position.openPrice)
    } else {
      win = (props.position.openPrice - (price.lastSale ? price.lastSale.price : price.ask))
    }
  }

  return <Link to={`/positions/${props.position.id}`}><Card
      hoverable
      style={{
        maxWidth: 300,
        backgroundColor: getColor(props.position.traderId) + '20',
        borderColor: getColor(props.position.traderId) + '60',
        fontFeatureSettings: "'ss01' 1, 'tnum' 1"
      }}
    >
    <span><b>{props.position.quantity} × {props.position.action} @{props.position.openPrice}</b></span><br />
    {
      price ? <>
        <Tag bordered={false} color={win > 0 ? '#52c41a' : '#ff4d4f'}>{win.toFixed(4)}</Tag> on {parseFloat((price.lastSale ? price.lastSale.price : price.bid).toFixed(4))}
      </> : <Skeleton />
    }<br />
    <span><i>{props.position.traderType} on {props.position.broker}</i></span><br />
  </Card></Link>
}

export { Position }