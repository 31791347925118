import { ApiTrader, ApiPosition, ApiDecision } from "../models"
import React, { FunctionComponent, useState, useEffect, useCallback } from "react"
import { Link, useParams } from "react-router"
import { instance } from "../utils"
import { Button, Modal, notification, Row, Col, Table, Tabs } from "antd"
import { TraderMeta } from "../components/trader/meta"
import { TablePaginationConfig } from "antd/lib/table/interface"
import { ModalFuncProps } from "antd/lib/modal/interface"
import { PaginationProps } from "antd/lib/pagination/Pagination"

const DEFAULT_COUNT = 20

type TraderParams = { id: string }

interface TraderProps {
  trader: ApiTrader
}

const TraderPage:FunctionComponent<{}> = (props) => {
  const params = useParams<TraderParams>()
  const [trader, setTrader] = useState<ApiTrader | null>(null)

  const [loadingPositions, setLoadingPositions] = useState<boolean>(false)
  const [positions, setPositions] = useState<ApiPosition[]>([])
  const [positionsPagination, setPositionsPagination] = useState<PaginationProps>({ pageSize: DEFAULT_COUNT })

  const [loadingDecisions, setLoadingDecisions] = useState<boolean>(false)
  const [decisions, setDecisions] = useState<ApiDecision[]>([])
  const [decisionsPagination, setDecisionsPagination] = useState<PaginationProps>({ pageSize: DEFAULT_COUNT })

  const [modal, contextHolder] = Modal.useModal();

  const modalDelete: ModalFuncProps = {
    title: 'Are you sure?',
    content: 'This will leave current positions as it is. You must close it manually.',
    onOk: async () => {
      try {
        await instance.delete(`/v1/traders/trader/${trader!.id}`)
        notification.success({
          message: 'Trader deleted',
          description: 'Actions opened are still open, you must close it manually'
        })
      } catch (e) {
        console.error(e)
        notification.error({
          message: 'Error',
          description: e.toString()
        })
      }
    }
  }

  const modalSellProfitable: ModalFuncProps = {
    title: 'Are you sure?',
    content: 'This will sell all current profitable positions. Is this emotionnal?',
    onOk: async () => {
      try {
        const res = await instance.post(`/v1/traders/trader/${trader!.id}/sellProfitable`)
        notification.success({
          message: 'Profitable positions sold',
          description: `Trader sold ${res.data.positionsSoldCount} positions`
        })
      } catch (e) {
        console.error(e)
        notification.error({
          message: 'Error',
          description: e.toString()
        })
      }
    }
  }

  const positionsColumns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Open',
          value: 'open',
        },
        {
          text: 'Close',
          value: 'closed'
        }
      ],
      onFilter: (value: any, position: ApiPosition) => position.status ? position.status === value : false
    },
    {
      title: 'Open date',
      dataIndex: 'openDate',
      key: 'openDate',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a: ApiPosition, b: ApiPosition) => a.quantity - b.quantity
    },
    {
      title: 'Open price',
      dataIndex: 'openPrice',
      key: 'openPrice',
      sorter: (a: ApiPosition, b: ApiPosition) => a.openPrice - b.openPrice
    },
    {
      title: 'Close date',
      dataIndex: 'closeDate',
      key: 'closeDate',
    },
    {
      title: 'Close price',
      dataIndex: 'closePrice',
      key: 'closePrice',
      sorter: (a: ApiPosition, b: ApiPosition) => {
        if (!a.closePrice) {
          return -Infinity
        }
        if (!b.closePrice) {
          return +Infinity
        }
        return a.closePrice - b.closePrice
      },
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      sorter: (a: ApiPosition, b: ApiPosition) => {
        if (!a.profit) {
          return -Infinity
        }
        if (!b.profit) {
          return +Infinity
        }
        return a.profit - b.profit
      },
      render: (profit: number, position: ApiPosition) => {
        if (profit === 0) {
          return ''
        }
        return profit.toFixed(4)
      }
    },
  ]
  const decisionsColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'In',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Wanted to',
      dataIndex: 'wantedTo',
      key: 'wantedTo'
    },
    {
      title: 'Out',
      dataIndex: 'result',
      key: 'result',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason'
    },
    {
      title: 'Meta',
      dataIndex: 'meta',
      key: 'meta',
      render: (e: any) => e ? <>{Object.keys(e).map(key => <>{key}: {e[key].toString()}<br /></>)}</> : null
    }
  ]

  const fetchData = useCallback(async () => {
    const trader = await instance.get(`/v1/traders/trader/${params.id}`)
    setTrader(trader.data.trader)
  }, [params.id])
  const fetchPositions = useCallback(async (skip: number = 0, take: number = DEFAULT_COUNT) => {
    setLoadingPositions(true)
    const positions = await instance.get(`/v1/positions?traderId=${params.id}&take=${DEFAULT_COUNT}&skip=${skip}`)
    setPositions(positions.data.positions)
    setPositionsPagination({
      ...positionsPagination,
      pageSize: take,
      total: positions.data.count,
    })
    setLoadingPositions(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])
  const fetchDecisions = useCallback(async (skip: number = 0, take: number = DEFAULT_COUNT) => {
    setLoadingDecisions(true)
    const decisions = await instance.get(`/v1/decisions?traderId=${params.id}&take=${take}&skip=${skip}`)
    setDecisions(decisions.data.decisions.sort((a: ApiDecision, b: ApiDecision) => new Date(b.date).getTime() - new Date(a.date).getTime()))
    setDecisionsPagination({
      ...decisionsPagination,
      pageSize: take,
      total: decisions.data.count,
    })
    setLoadingDecisions(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  useEffect(() => {
    fetchData()
    fetchPositions()
    fetchDecisions()
  }, [fetchData, fetchDecisions, fetchPositions, params.id])

  return <>
    { trader ? <TraderMeta trader={trader} full={true} /> : <p>Loading...</p> }<br />
    <Link to={`/traders/trader/${trader?.id}/edit`}><Button type="primary">Edit trader</Button></Link><br />
    <Link to={`/traders/trader/${trader?.id}/addPosition`}><Button>Manual add position</Button></Link>
    <Button>Force buy {trader?.quantity}</Button>
    <Button>Force sell all</Button>
    <Button onClick={() => modal.confirm(modalSellProfitable)}>Force sell profitable</Button><br />
    <Button color="danger" variant='solid' onClick={() => modal.confirm(modalDelete)}>Delete</Button>
    <br /><br />

    <Tabs defaultActiveKey="1" style={{ fontFeatureSettings: "'ss01' 1, 'tnum' 1" }}>
      <Tabs.TabPane tab="Positions" key="1">
        <Table dataSource={positions} columns={positionsColumns} loading={loadingPositions} pagination={positionsPagination} onChange={(pagination: PaginationProps) => {
          fetchPositions((((pagination.current) ?? 1) - 1) * DEFAULT_COUNT, pagination.pageSize)
        }} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Decisions" key="2">
        <Table dataSource={decisions} columns={decisionsColumns} loading={loadingDecisions} pagination={decisionsPagination} onChange={(pagination: PaginationProps) => {
          fetchDecisions((((pagination.current) ?? 1) - 1) * DEFAULT_COUNT, pagination.pageSize)
        }} />
      </Tabs.TabPane>
    </Tabs>
    

    {contextHolder}
  </>
}

export { TraderPage }